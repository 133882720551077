/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($){

	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var Sage = {
		// All pages
		'common': {
			init: function(){
				// JavaScript to be fired on all pages

				$("#menu-primary-navigation, #menu-primary-navigation-french").find(".dropdown-toggle").attr("data-toggle", "");

			},
			finalize: function(){
				// JavaScript to be fired on all pages, after page specific JS is fired
			}
		},
		// Home page
		'home': {
			init: function(){
				// JavaScript to be fired on the home page

				$("#header-banner-carousel").carousel({
					interval: 	5000,
					pause:		"hover"
				});

			},
			finalize: function(){
				// JavaScript to be fired on the home page, after the init JS
			}
		},
		// Blog post listing page
		'blog': {
			init: function(){
				// JavaScript to be fired on the about us page

				// Content list infinite feed
				if($("button.load-more-btn").length > 0){

					if($("#list-content-loader").length < 1){
						$("button.load-more-btn").hide();
					}

					$("button.load-more-btn").on("click", function(){

						var contentLoader = $("#list-content-loader");

						var loadMoreBtn = $(this);

						var origButtonText = loadMoreBtn.html();

						loadMoreBtn
							.attr("disabled", true)
							.css("width", loadMoreBtn.outerWidth())
							.html('<span class="fa fa-spinner fa-spin"></span>');

						var action = "get_posts";

						if((contentLoader.data("action") !== undefined) && (contentLoader.data("action") !== "")){
							action = contentLoader.data("action");
						}

						$.post(
							ajaxVars.ajaxurl,
							{
								"action": action,
								"data": $.param(contentLoader.data())
							},
							function(data){

								contentLoader.remove();

								if(data){

									loadMoreBtn
										.attr("disabled", false)
										.html(origButtonText)
										.css("width", "auto");

									$("#blog-list-holder").append(data);

									// Look for the new content loader tag
									// If it's not there, there aren't any more posts to show so hide the view more button
									if($("#list-content-loader").length < 1){
										loadMoreBtn.parent().remove();
									}

								}else{

									loadMoreBtn.parent().remove();

									$("#blog-list-holder").append('<p>No more posts available</p>');

								}

							}
						);

					});

				}

			}
		},
		"single_post": {
			init: function(){

				var dropdownDirObserver = new MutationObserver(function(mutations){
					mutations.forEach(function(mutation){

						if(mutation.type === "attributes" && mutation.attributeName === "x-placement"){

							if($(mutation.target).attr("x-placement") === "top-start"){

								$(mutation.target).addClass("dropup");

							}else{

								$(mutation.target).removeClass("dropup");

							}

						}

					});
				});

				dropdownDirObserver.observe($("#post-footer-share-buttons-trigger").next().get(0), {
					attributes: true //configure it to listen to attribute changes
				});

			}
		}
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function(func, funcname, args){
			var fire;
			var namespace = Sage;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if(fire){
				namespace[func][funcname](args);
			}
		},
		loadEvents: function(){
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm){
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
